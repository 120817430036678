<template>
    <div>
        <!-- ----------- -->
        <!-- Editor View -->
        <!-- ----------- -->
        <div v-if="editorview" id="editorview" class="mb-5">
            <div class="d-flex mt-4" v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)">
                <v-btn small v-if="!treeData.length" color="cyan darken-2" class="mr-auto ml-1" text @click="addPreset()">
                    <v-icon small class="mr-1">mdi-plus</v-icon>
                    custom headline
                </v-btn>
                <v-divider class="mx-auto" v-if="!treeData.length" vertical></v-divider>
                <v-btn
                    small
                    color="cyan darken-2"
                    class="ml-auto mr-1"
                    text
                    @click="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2) ? (showGenerator = true) : null">
                    <v-icon small class="mr-1">mdi-auto-fix</v-icon>
                    Ai generator
                </v-btn>
            </div>
            <v-row class="flex mt-5 px-1" no-gutters v-if="treeData.length">
                <div class="my-auto pl-2 ml-auto" style="color: #797979; padding-right: 12.4444444444px">{{ headlineCount }} headings</div>
                <v-divider v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" vertical class="mx-0"></v-divider>
                <v-btn v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" small class="px-2" color="primary" text @click="treeData = []">
                    <!-- <v-icon small class="mr-1">mdi-creation-outline</v-icon> -->
                    clear all
                </v-btn>
                <v-divider vertical class="mx-0"></v-divider>

                <v-btn
                    v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)"
                    small
                    class="px-2 mr-auto"
                    color="primary"
                    text
                    @click="$emit('pasteOutline', treeData)">
                    <!-- <v-icon small class="mr-1">mdi-creation-outline</v-icon> -->
                    paste headlines
                </v-btn>
            </v-row>

            <v-treeview id="outlinetree" expand-icon="mdi-chevron-down" class="" :items="treeData" item-key="id" :open.sync="openNodes" open-all>
                <template v-slot:append="{ item }">
                    <div v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" class="button-container">
                        <v-btn icon small @click.stop="editItem(item)">
                            <v-icon class="outlinebtn" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn hint="add sub headline" icon small @click.stop="addChildItem(item)">
                            <v-icon class="outlinebtn">mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </template>
                <template v-slot:label="{ item }">
                    <div
                        @click="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2) ? editItem(item) : null"
                        style="font-size: 0.9rem; padding-top: 4px; padding-bottom: 4px">
                        {{ item.text }}
                    </div>
                </template>
                <template v-slot:prepend="{ item }">
                    <v-chip
                        small
                        class="white--text px-1"
                        @click="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2) ? editItem(item) : null"
                        :color="placementColors[item.tag.toUpperCase()]"
                        >{{ item.tag.toUpperCase() }}</v-chip
                    >
                </template>
            </v-treeview>
            <v-row v-if="treeData.length" style="background-color: #f6f6f6">
                <v-btn
                    v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)"
                    color="primary"
                    class="mx-auto"
                    text
                    small
                    @click.stop="addChildItem(treeData[treeData.length - 1])">
                    <v-icon class="outlinebtn">mdi-plus</v-icon> add headline
                </v-btn>
            </v-row>
        </div>

        <!-- ------------- -->
        <!-- Briefing View -->
        <!-- ------------- -->
        <v-card flat v-else style="min-height: 250px">
            <!-- <div class="backsheet"></div> -->

            <!-- <v-card-title class="outline_card_label">Headlines</v-card-title> -->
            <div v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" class="d-flex mt-4 mr-4 mb-4">
                <v-btn v-if="!this.project.outline.length" color="cyan darken-2" class="ml-auto" text @click="addPreset()">
                    <v-icon class="mr-1">mdi-plus</v-icon>
                    custom headline
                </v-btn>
                <v-divider v-if="!this.project.outline.length" vertical></v-divider>
                <v-btn small color="primary" :class="this.project.outline.length > 0 ? 'ml-auto my-auto' : 'ml-4 my-auto'" rounded @click="showGenerator = true">
                    <v-icon class="mr-1">mdi-creation-outline</v-icon>
                    ai headline generator
                </v-btn>
            </div>
            <!-- {{ openNodes }} -->

            <!-- <v-treeview id="outlinetree" class="pb-5" :items="treeData" item-key="id" :open.sync="openNodes" open-all>
                <template v-slot:append="{ item }">
                    <div class="button-container">
                        <v-btn v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" icon small @click.stop="editItem(item)">
                            <v-icon class="outlinebtn" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" icon small @click.stop="addChildItem(item)">
                            <v-icon class="outlinebtn">mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </template>
                <template v-slot:label="{ item }">
                    <div @click="!shared_extern && $store.state.content_suite_group.id == 1 ? editItem(item) : null">
                        <v-divider vertical color="red"></v-divider>

                        <span class="pl-2"> {{ item.text }}</span>
                    </div>
                </template>
                <template v-slot:prepend="{ item }">
                    <v-chip
                        class="white--text"
                        @click="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2) ? editItem(item) : null"
                        :color="placementColors[item.tag]"
                        >{{ item.tag }}</v-chip
                    >
                </template>
            </v-treeview> -->
            <draggable v-model="project.outline" tag="ul">
                <div
                    class="draggableheadlines"
                    style="
                        cursor: grab;
                        border-left: 2px solid var(--v-primary-base);
                        min-height: 80px !important;
                        border-radius: 10px;
                        padding-left: 15px;
                        background-color: #f6f6f6;
                        display: flex;
                        margin-bottom: 16px;
                        margin-right: 40px;
                    "
                    v-for="(item, index) in project.outline"
                    :key="item.id">
                    <!-- <div style="position: relative">
                        <div style="position: absolute; bottom: -8px; right: 50%">
                            <v-btn icon> <v-icon>mdi-plus</v-icon></v-btn>
                        </div>
                    </div> -->
                    <div style="cursor: pointer; height: 80px; padding-right: 15px; display: flex; align-items: center; border-right: 2px dotted var(--v-primary-base)">
                        <v-chip class="white--text" :color="placementColors[item.tag.toUpperCase()]">{{ item.tag.toUpperCase() }}</v-chip>
                    </div>
                    <div style="display: flex; font-size: inherit; overflow: hidden; text-overflow: ellipsis; white-space: normal; cursor: pointer">
                        <div class="my-auto pl-4">
                            <span style="font-size: 16px">{{ item.text }}</span>
                        </div>
                    </div>
                    <div class="button-container" style="cursor: pointer; width: 90px; margin-left: auto; display: flex; align-items: center">
                        <v-btn v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" icon small @click.stop="editItem(item, index)">
                            <v-icon class="outlinebtn" small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" icon small @click.stop="addChildItem(item, index)">
                            <v-icon class="outlinebtn">mdi-plus</v-icon>
                        </v-btn>
                        <v-btn v-if="!shared_extern && ($store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2)" icon small @click.stop="deleteHeadline(index)">
                            <v-icon class="outlinebtn">mdi-delete-outline</v-icon>
                        </v-btn>
                    </div>
                    <div style="position: absolute; left: calc(50% - 68px); bottom: -50px" class="d-flex" v-if="index == project.outline.length - 1">
                        <v-btn color="primary" @click.stop="addChildItem(item, index)" text><v-icon>mdi-plus</v-icon> headline</v-btn>
                    </div>
                </div>
            </draggable>
        </v-card>

        <v-dialog v-model="editDialog" max-width="500px">
            <v-card>
                <v-card-title class="outline_card_label"
                    >Edit Headline

                    <v-btn right absolute icon color="primary" @click="editDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                    <v-select rounded filled v-model="editedItem.tag" :items="tagOptions" label="Tag"></v-select>
                    <v-text-field hide-details="" rounded filled v-model="editedItem.text" label="Text"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="saveEditedItem">Save</v-btn>
                    <v-btn class="ml-auto" color="error" text @click="deleteHeadline">delete headline</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addChildDialog" max-width="500px">
            <v-card>
                <v-card-title class="outline_card_label"> Add Headline</v-card-title>
                <v-card-text>
                    <v-select rounded filled v-model="newChild.tag" :items="tagOptions" label="Tag"></v-select>
                    <v-text-field hide-details rounded filled v-model="newChild.text" label="Write headline..."></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="saveNewChild">Add</v-btn>
                    <v-btn text @click="addChildDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <OutlineGenerator
            :show="showGenerator"
            :mainKeyword="project.main_keyword"
            @close="showGenerator = false"
            @insertOutline="insertOutline"
            :projectLanguage="project.langloc.language_code"
            :selectedCompetitors="selectedCompetitors"></OutlineGenerator>
    </div>
</template>

<script>
    import Vue from "vue"
    import draggable from "vuedraggable"

    import OutlineGenerator from "./OutlineGenerator.vue"
    export default {
        components: {
            OutlineGenerator,
            draggable
        },
        props: {
            editorview: Boolean,
            project: Object,
            shared_extern: Boolean,
            headlineToAdd: Object
        },
        data() {
            return {
                selectedCompetitors: [], //for genereator
                showGenerator: false,
                placementColors: {
                    H1: "cyan darken-3",
                    H2: "purple darken-3",
                    H3: "lime darken-3",
                    H4: "light-green darken-3",
                    H5: "blue darken-3",
                    H6: "orange darken-3",
                    description: "grey darken-2",
                    title: "grey darken-2"
                },
                treeData: [
                    {
                        id: this.generateUniqueId(),
                        tag: "H1",
                        text: "First Headline",
                        children: [
                            { id: this.generateUniqueId(), tag: "H2", text: "Headline 1.1", children: [] },
                            { id: this.generateUniqueId(), tag: "H2", text: "Headline 1.2" }
                        ]
                    }
                    // {
                    //     id: 2,
                    //     tag: "H1",
                    //     text: "Item 2",
                    //     children: [
                    //         { id: 21, tag: "H2", text: "Item 2.1" },
                    //         { id: 22, tag: "H2", text: "Item 2.2" }
                    //     ]
                    // }
                ],
                openNodes: [],
                editDialog: false,
                editedItem: {
                    id: null,
                    text: ""
                },
                addChildDialog: false,
                newChild: {
                    tag: "",
                    text: ""
                },
                editHeadline: {},
                tagOptions: ["H1", "H2", "H3", "H4", "H5", "H6"]
            }
        },
        methods: {
            flattenHierarchy(items) {
                const flattened = []

                function recursiveFlatten(item, parentId = null) {
                    // Füge das aktuelle Element hinzu
                    const { children, ...rest } = item
                    flattened.push({ ...rest, parentId })

                    // Verarbeite die Kinder, falls vorhanden
                    if (children) {
                        children.forEach((child) => recursiveFlatten(child, item.id))
                    }
                }

                items.forEach((item) => recursiveFlatten(item))
                return flattened
            },
            addPreset() {
                this.project.outline.push({ tag: "H1", text: "First Headline" })

                this.treeData.push({
                    id: this.generateUniqueId(),
                    tag: "H1",
                    text: "First Headline",
                    children: [
                        { id: this.generateUniqueId(), tag: "H2", text: "Headline 1.1", children: [] },
                        { id: this.generateUniqueId(), tag: "H2", text: "Headline 1.2" }
                    ]
                })
                this.expandAllNodes(this.treeData)
            },
            deleteHeadline(index) {
                this.project.outline.splice(index, 1)
                this.updateOutline()
            },
            insertOutline(outline) {
                console.log(outline)
                // this.treeData = []
                // this.treeData.push(outline)
                this.showGenerator = false
                // this.openNodes = []
                // this.expandAllNodes(this.treeData)
                this.project.outline = [...this.project.outline, ...outline]
                // this.updateOutline()
            },
            expandAllNodes(nodes) {
                nodes.forEach((node) => {
                    this.openNodes.push(node.id) // Add node id to openNodes array
                    if (node.children && node.children.length > 0) {
                        this.expandAllNodes(node.children) // Recursively expand child nodes
                    }
                })
            },
            updateOpenNodes(openNodes) {
                this.openNodes = openNodes
            },
            editItem(item, index) {
                this.editedItem.id = index
                this.editedItem.tag = item.tag
                this.editedItem.text = item.text
                this.editDialog = true
            },
            async updateOutline() {
                const url = process.env.VUE_APP_APIURL + "/cs/outline/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    id: this.project._id,
                    outline: this.project.outline
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.$emit("updateOutline", this.treeData)
            },
            saveEditedItem() {
                // Implement your save logic here
                // For example, update the item in the treeData array

                // const item = this.findItemById(this.treeData, this.editedItem.id)
                // if (item) {
                //     item.tag = this.editedItem.tag
                //     item.text = this.editedItem.text
                // }
                this.project.outline[this.editedItem.id].tag = this.editedItem.tag
                this.project.outline[this.editedItem.id].text = this.editedItem.text
                this.editDialog = false

                this.updateOutline()
            },
            addChildItem(parentItem, index = null) {
                console.log("parentItem", parentItem)
                this.newChild.parentId = parentItem.id // Setze die parentId für das neue Child-Element
                this.newChild.index = index
                let tag = "H" + (parseInt(parentItem.tag.substring(1, 2)) + 1)
                this.newChild.tag = tag
                this.newChild.text = ""
                this.addChildDialog = true
            },
            saveNewChild() {
                // Implement your save logic here
                // For example, generate a new unique ID for the child item and add it to the parent's children array
                // const parentItem = this.treeData.find((item) => item.id === this.newChild.parentId)
                if (this.newChild.index !== null) {
                    this.project.outline.splice(this.newChild.index + 1, 0, { text: this.newChild.text, tag: this.newChild.tag })
                }
                // const parentItem = this.findItemById(this.treeData, this.newChild.parentId)
                // if (parentItem) {
                //     const newChild = {
                //         id: this.generateUniqueId(),
                //         text: this.newChild.text,
                //         tag: this.newChild.tag,
                //         children: []
                //     }
                //     if (!parentItem.children) parentItem.children = []
                //     Vue.set(parentItem.children, parentItem.children.length, newChild)
                //     // parentItem.children.push(newChild)
                //     this.openNodes.push(parentItem.id) // Öffne das Elternelement des hinzugefügten Child-Elements
                // }
                // let help = JSON.parse(JSON.stringify(this.treeData))
                // this.treeData = []
                // this.treeData = help
                // console.log(this.treeData)
                this.addChildDialog = false
                this.newChild.parentId = null // Zurücksetzen des parentId-Werts

                this.updateOutline()
            },
            generateUniqueId() {
                return new Date().getTime().toString() + Math.floor(Math.random() * 1000)
            },

            removeElementById(dataArray, targetId) {
                // Rekursive Funktion, um das Element mit der gegebenen id aus dataArray zu entfernen
                return dataArray.filter((item) => {
                    if (item.id === targetId) {
                        // Das Element mit der Ziel-ID wird nicht in das Ergebnis aufgenommen (also entfernt).
                        return false
                    } else if (item.children && item.children.length > 0) {
                        // Wenn das Element Kinder hat, rufen wir die Funktion rekursiv für die Kinder auf.
                        item.children = this.removeElementById(item.children, targetId)
                    }
                    return true // Das Element wird in das Ergebnis aufgenommen.
                })
            },
            findItemById(treeData, id) {
                // Durchlaufe jedes Element im Array
                for (let i = 0; i < treeData.length; i++) {
                    const item = treeData[i]

                    // Überprüfe, ob die ID des aktuellen Elements übereinstimmt
                    if (item.id === id) {
                        return item // Gefundenes Element zurückgeben
                    }

                    // Überprüfe, ob das aktuelle Element Kinder hat
                    if (item.children && item.children.length > 0) {
                        // Rufe die Funktion rekursiv auf den Kinder-Array auf
                        const foundItem = this.findItemById(item.children, id)
                        if (foundItem) {
                            return foundItem // Gefundenes Element zurückgeben
                        }
                    }
                }

                // ID wurde nicht gefunden
                return null
            }
        },
        mounted() {
            console.log(this.project.outline)
            if (this.project.outline.length) this.treeData = this.project.outline
            this.expandAllNodes(this.treeData)
            this.project.outline = this.flattenHierarchy(this.project.outline)
            this.selectedCompetitors = this.project.competitors.filter((c) => c.selected)
            if (this.project.outline.length == 0) {
                this.project.outline = [
                    { tag: "H1", text: "Headline 1.1" },
                    { tag: "H2", text: "Headline 1.2" }
                ]
            }
        },
        computed: {
            // projectHeadings: {
            //     set(val) {
            //         this.project.headings = val
            //     },
            //     get() {
            //         return this.flattenHierarchy(this.treeData)
            //     }
            // },
            headlineCount() {
                const jsonString = JSON.stringify(this.treeData)
                const count = (jsonString.match(/}/g) || []).length
                return count
            }
        },
        watch: {
            headlineToAdd(val) {
                console.log(val)

                this.project.outline.push(val)
            },
            // projectHeadings: {
            //     set(val) {
            //         this.project.headings = val
            //     },
            //     get() {
            //         return this.flattenHierarchy(this.treeData)
            //     }
            // },
            project: {
                handler(val) {
                    this.updateOutline()
                },
                deep: true
            }
        }
    }
</script>
<style>
    .draggableheadlines:hover .outlinebtn {
        display: flex;
    }
    .draggableheadlines .outlinebtn {
        display: none;
    }
    #outlinetree .v-treeview-node__root:hover .v-treeview-node__append .outlinebtn {
        display: flex;
    }
    #editorview #outlinetree .v-treeview-node__prepend {
        padding-right: 4px;
    }
    #editorview #outlinetree .v-treeview-node__root {
        margin: 15px 0px;
        padding-left: 0px;
    }
    #editorview #outlinetree .v-treeview-node__content {
        padding-left: 4px;
        margin-left: 0px;
    }
    #outlinetree .v-treeview-node__root {
        margin: 15px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        position: relative;
    }
    #outlinetree .v-treeview-node__content {
        /* border: 2px solid grey; */
        border-left: 2px solid var(--v-primary-base);
        min-height: 80px !important;
        border-radius: 10px;
        padding-left: 15px;
        background-color: #f6f6f6;
    }
    .theme--dark #outlinetree .v-treeview-node__content {
        background-color: black;
    }
    #outlinetree .v-treeview-node__label {
        flex: 1;
        font-size: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        cursor: pointer;
    }
    #outlinetree .v-treeview-node__append {
        height: 80px;
        position: relative;
    }
    #outlinetree .v-treeview-node__prepend {
        cursor: pointer;
        height: 80px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        /* position: relative; */
        border-right: 2px dotted var(--v-primary-base);
        /* border-top: 2px solid var(--v-primary-base);
        border-bottom: 2px solid var(--v-primary-base); */
    }
    #outlinetree .button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 12px;
        margin-right: 5px;
    }

    #outlinetree .outlinebtn {
        /* margin-top: 10px; */

        display: none;
    }
    .outline_card_label {
        font-size: 1rem;
        color: rgb(101, 101, 101);
        text-transform: uppercase;
    }
</style>
