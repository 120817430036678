<template>
    <div id="contentcontainter" class="standard-mode">
        <div style="max-width: 1800px; margin: auto">
            <v-row class="mt-5">
                <v-col>
                    <v-card
                        style="background: linear-gradient(180deg, var(--v-left_nav_gradient1-base) 0%, var(--v-left_nav_gradient2-base) 59%, #0a7d6d91 100%)"
                        color="primary"
                        max-width="600px"
                        class="mr-auto rounded_card quaroshadow">
                        <v-card-text style="padding-top: 5px; padding-bottom: 5px">
                            <v-row>
                                <v-col class="white--text">
                                    <h1 class="my-2">
                                        {{ bannertext.title }}
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </h1>

                                    <p class="mb-3 mt-2" style="font-size: 16px">{{ bannertext.firstp }}</p>

                                    <!-- <v-btn rounded color="grey" color="white" class="pl-2" small @click="$router.push('/monitoring/setup')">
                                    <v-icon small class="mr-1">mdi-video</v-icon>
                                    watch tutorial</v-btn
                                > -->
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col>
                    <v-card max-width="600px" height="600px" class="mr-auto rounded_card quaroshadow">
                        <v-card-text>
                            <v-row>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img width="120" class="mx-auto" src="../assets/undraw_folder.svg" />
                                </v-col>
                                <v-col md="8" sm="8">
                                    <h2 class="my-2">
                                        {{ researchText.title }}
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </h2>

                                    <p class="mb-3">{{ researchText.firstp }}</p>

                                    <v-btn rounded color="grey" class="pl-2" small @click="$router.push('/monitoring/setup')">
                                        <v-icon small class="mr-1">mdi-video</v-icon>
                                        watch tutorial</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card max-width="600px" height="600px" class="mr-auto rounded_card quaroshadow">
                        <v-card-text>
                            <v-row>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img width="120" class="mx-auto" src="../assets/undraw_folder.svg" />
                                </v-col>
                                <v-col md="8" sm="8">
                                    <h2 class="my-2">
                                        {{ contenttext.title }}
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </h2>

                                    <p class="mb-3">{{ contenttext.firstp }}</p>

                                    <v-btn rounded color="grey" class="pl-2" small @click="$router.push('/monitoring/setup')">
                                        <v-icon small class="mr-1">mdi-video</v-icon>
                                        watch tutorial</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card max-width="600px" height="600px" class="mr-auto rounded_card quaroshadow">
                        <v-card-text>
                            <v-row>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img width="120" class="mx-auto" src="../assets/undraw_folder.svg" />
                                </v-col>
                                <v-col md="8" sm="8">
                                    <h2 class="my-2">
                                        {{ monitoringtext.title }}
                                        <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                    </h2>

                                    <p class="mb-3">{{ monitoringtext.firstp }}</p>

                                    <v-btn rounded color="grey" class="pl-2" small @click="$router.push('/monitoring/setup')">
                                        <v-icon small class="mr-1">mdi-video</v-icon>
                                        watch tutorial</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from "@/components/HelloWorld.vue"

    export default {
        name: "Home",
        components: {},
        data() {
            return {
                bannertext: {
                    title: "Hi Alexander,",
                    firstp: "welcome back! What do you want to do today?"
                },
                researchText: {
                    title: "Keyword Research",
                    subtitle: "Research Suite",
                    firstp: "welcome back! What do you want to do today?"
                },
                contenttext: {
                    title: "Content Creation",
                    subtitle: "Content Suite",
                    firstp: "welcome back! What do you want to do today?"
                },
                monitoringtext: {
                    title: "Keyword Monitoring",
                    subtitle: "Monitoring Suite",
                    firstp: "welcome back! What do you want to do today?"
                }
            }
        }
    }
</script>
